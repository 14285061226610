import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function roleManager() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refRoleManagerTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: false },
    { key: 'createdAt', sortable: false },
    { key: 'updatedAt', sortable: false },
    { key: 'action', sortable: false },

  ]
  const perPage = ref(10)
  const totalRoleManager = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refRoleManagerTable.value
      ? refRoleManagerTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRoleManager.value,
    }
  })

  const refetchData = () => {
    refRoleManagerTable.value.refresh()
  }
  const fetchRoleManager = (ctx, callback) => {
    store
      .dispatch('roleManager/fetchRoleManager', {
        q: searchQuery.value === '' ? null : searchQuery.value,
        take: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const rates = response.body.data
        totalRoleManager.value = response.body.meta.itemCount
        callback(rates)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching role list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const timeout = ref(null)
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
    ],
    () => {
      showTable.value = true
      clearTimeout(timeout)
      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 600)
    },
  )
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchRoleManager,
    tableColumns,
    perPage,
    currentPage,
    totalRoleManager,
    dataMeta,
    perPageOptions,
    searchQuery,
    refRoleManagerTable,
    refetchData,
    // Extra
  }
}
